<template>
  <div class="container">
    <h4 class="text-center my-4">Заявления на академ задолженности</h4>

    <PrimePreloader v-if="loading"/>

    <div v-else class="table-responsive">

      <table class="table table-bordered table-striped">
        <thead>
        <tr>
          <th>№</th>
          <th>Дисциплина</th>
          <th>Кредит</th>
          <th>Язык</th>
          <th>Уровень</th>
          <th>Количество студентов</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(application, applicationIndex) in academicDebtService.confirmedApplications"
            :key="applicationIndex">
          <td>{{applicationIndex+1}}</td>
          <td>
            <router-link
              class="text-decoration-none"
              :to="`/academic-debt/courses?discipline_id=${application.education_discipline_id}&is_oldmoodle=${application.is_oldmoodle}`">
              {{application.discipline_name}}
            </router-link>
          </td>
          <td>{{application?.credit}}</td>
          <td>{{application?.language}}</td>
          <td>{{application?.study_level_name}}</td>
          <td>
            <div v-if="application?.students.length">
              <Button :label="application?.students.length" class="p-button-secondary p-button-rounded"
                      @click="openStudentsModal(application.discipline_name, application.is_oldmoodle, application.education_discipline_id, application.is_practice)"/>
            </div>
            <div v-else>0</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <Dialog :header="selectedDisciplineName" v-model:visible="displayStudents"
            :style="{width: '100%', maxWidth: '600px'}"
            :modal="true">
      <div>
        <p>Выберите преподавателя</p>
        <div class="input-group mb-3">
          <select class="form-control" v-model="teacherId">
            <option v-for="(teacher, teacherIndex) in academicDebtService.pps" :value="teacher.id" :key="teacherIndex">
              {{teacher.lastname}} {{teacher.firstname}} {{teacher.middlename}}
            </option>
          </select>
        </div>

        <p class="mt-3">Выберите студентов</p>

        <div>
          <div class="form-check my-2" v-for="(student, studentIndex) in studentsByDisciplineId" :key="studentIndex">
            <input class="form-check-input" type="checkbox"
                   :id="'checkStudent'+studentIndex"
                   :value="student.student_summer_course_id"
                   v-model="selectStudents">
            <label class="form-check-label" :for="'checkStudent'+studentIndex">
              {{studentIndex+1}}. {{student.barcode}} {{student.last_name}} {{student.first_name}}
            </label>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Отмена" icon="pi pi-times" @click="closeStudentsModal" class="p-button-text"/>
        <Button label="Создать курс" icon="pi pi-plus" @click="postSummerCourse" autofocus
                :disabled="!teacherId||!selectStudents.length||btnCreateCourseStatus"/>
      </template>
    </Dialog>

  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate";

  export default {
    name: "AcademicDebtDepartmentHead",
    data() {
      return {
        loading: true,
        applicationStatuses: {
          0: 'Оправлено',
          1: 'Подтверждено',
          2: 'Отклонено'
        },
        changingStatus: false,
        displayStudents: false,
        selectedDisciplineName: '',
        education_discipline_id: 0,
        selectStudents: [],
        teacherId: 0,
        is_oldmoodle: 0,
        is_practice: 0,
        btnCreateCourseStatus: false
      }
    },
    computed: {
      ...mapState('academicDebtService', ['academicDebtService']),
      ...mapState('ppsLoad', ['ppsLoad_form']),
      studentsByDisciplineId() {
        return this.academicDebtService.confirmedApplications.find(i => i.education_discipline_id == this.education_discipline_id && i.is_oldmoodle == this.is_oldmoodle)?.students || []
      }
    },
    methods: {
      ...mapActions('academicDebtService', ['GET_CONFIRMED_APPLICATIONS', 'PUT_APPLICATION_STATUS', 'GET_PPS', 'GET_STUDENTS_BY_DESCIPLINE_ID',
        'POST_SUMMER_COURSE']),
      ...mapMutations('academicDebtService', ['CHANGE_PPS']),

      ...mapActions('ppsLoad', ['GET_TEACHERS',]),
      convertGraduateWorkTimestampToDate,
      async changeApplicationStatus(id, status) {
        this.changingStatus = true
        const res = await this.PUT_APPLICATION_STATUS({id, status})
        if (res) {
          await this.GET_CONFIRMED_APPLICATIONS()
          this.$message({text: `Успешно. Статус ${this.applicationStatuses[status]}`})
        }
        this.changingStatus = false
      },
      changePps(discipline_id, e) {
        this.CHANGE_PPS({discipline_id, pps_id: e.target.value})
      },
      async openStudentsModal(disciplineName, is_oldmoodle, education_discipline_id, is_practice) {
        this.teacherId = 0
        this.selectStudents = []
        this.selectedDisciplineName = disciplineName
        this.education_discipline_id = education_discipline_id
        this.is_oldmoodle = is_oldmoodle
        this.is_practice = is_practice

        // await this.GET_TEACHERS(education_discipline_id)

        this.displayStudents = true
      },
      closeStudentsModal() {
        this.displayStudents = false
      },
      async postSummerCourse() {
        console.log(this.teacherId, this.selectStudents, 'selectStudents')

        if (!this.teacherId || !this.selectStudents.length) {
          this.$error({text: `Выберите преподавателя и студента (студентов)`})
          return
        }
        this.btnCreateCourseStatus = true
        await this.POST_SUMMER_COURSE({
          pps_id: this.teacherId,
          student_summer_course: this.selectStudents,
          is_oldmoodle: this.is_oldmoodle,
          is_practice: this.is_practice
        })
        await this.GET_CONFIRMED_APPLICATIONS()
        this.btnCreateCourseStatus = false
        this.closeStudentsModal()
      }
    },
    async mounted() {
      await this.GET_CONFIRMED_APPLICATIONS()
      await this.GET_PPS()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>